import { Typography } from '@mui/material'

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <Typography variant="h5">Privacy Policy</Typography>
      <Typography variant="body1">
        Welcome to Mamas List App's Privacy Policy. We respect your privacy and are committed to protecting your
        personal data. This privacy policy will inform you about how we treat your personal data when you use our
        application.
      </Typography>

      <Typography variant="h5">Data We Collect</Typography>
      <Typography variant="body1">
        Our app allows you to input your market list. All the data you input is stored exclusively on your device. We do
        not collect, process, or store any of this data on external servers or third-party platforms.
      </Typography>

      <Typography variant="h5">How We Use Data</Typography>
      <Typography variant="body1">
        We use the data you input only to display and manage your market list within the app. This data remains on your
        device and is not accessed, transferred, or used by us for any other purpose, unless requested by you.
      </Typography>

      <Typography variant="h5">Data Storage and Security</Typography>
      <Typography variant="body1">
        All data is stored locally on your device. It's essential to note that if your device is lost, damaged, or
        replaced, the data within the app may also be lost unless you have backed it up.
      </Typography>

      <Typography variant="h5">Changes to This Privacy Policy</Typography>
      <Typography variant="body1">
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy
        Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
      </Typography>

      <Typography variant="h5">Contact Us</Typography>
      <Typography variant="body1">
        If you have any questions about this Privacy Policy, please contact us at hello@newdev.io.
      </Typography>
    </div>
  )
}

export default PrivacyPolicy
