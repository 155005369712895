import { useNavigate, useLocation } from 'react-router-dom'
import { Box, BottomNavigation, BottomNavigationAction } from '@mui/material'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import StarIcon from '@mui/icons-material/Star'
import DeleteIcon from '@mui/icons-material/Delete'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import './common.scss'

const BottomNav = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Box className="bottom-nav">
      <BottomNavigation
        sx={{
          justifyContent: 'space-around',
          '& .Mui-selected, .Mui-selected > svg': {
            color: '#FF6B00',
          },
        }}
        showLabels
        className="navigation-wrapper"
        value={location.pathname}
        onChange={(_, newValue) => {
          navigate(newValue)
        }}
      >
        <BottomNavigationAction label="Lists" value="/lists" icon={<ShoppingBasketIcon />} />
        <BottomNavigationAction label="Popular" value="/popular" icon={<StarIcon />} />
        <BottomNavigationAction label="Recipes" value="/recipes" icon={<MenuBookIcon />} />
        <BottomNavigationAction label="Trash" value="/trash" icon={<DeleteIcon />} />
        <BottomNavigationAction label="Settings" value="/settings" icon={<SettingsSuggestIcon />} />
      </BottomNavigation>
    </Box>
  )
}

export default BottomNav
