import { useEffect, useRef, useState } from 'react'
import { DebounceInput } from 'react-debounce-input'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useLiveQuery } from 'dexie-react-hooks'
import { Button, Pagination, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import database from '../../database'
import data from './ingredients.json'
import './popular.scss'

const Popular = () => {
  const numberOfItemsPerPage = 14
  const pageTopRef = useRef(null)
  const [dataRange, setDataRange] = useState({ startIndex: 0, endIndex: numberOfItemsPerPage })
  const [filteredData, setFilteredData] = useState(data?.slice(dataRange.startIndex, dataRange.endIndex))
  const [filterQuery, setFilterQuery] = useState('')

  useEffect(() => {
    if (!filterQuery) {
      setFilteredData(data?.slice(dataRange.startIndex, dataRange.endIndex))
    } else {
      const queryString = filterQuery.toLowerCase()
      const filteredData = data?.filter((item) => item.strIngredient.toLowerCase().includes(queryString))
      setDataRange({ startIndex: 0, endIndex: numberOfItemsPerPage })
      setFilteredData(filteredData)
    }
  }, [dataRange.endIndex, dataRange.startIndex, filterQuery])

  const setPageData = (pageNumber) => {
    const startIndex = pageNumber * numberOfItemsPerPage - numberOfItemsPerPage
    const endIndex = startIndex + numberOfItemsPerPage
    setDataRange({ startIndex, endIndex })
    pageTopRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const allItems = useLiveQuery(() => database.lists.reverse().toArray(), [])
  if (!allItems) return null
  const shoppingList = allItems?.map(({ name }) => name)

  return (
    <div className="popular">
      <div className="search-field-wrapper" tabIndex="1" ref={pageTopRef}>
        <DebounceInput
          className="search-field"
          name="search-field"
          placeholder="search..."
          minLength={1}
          debounceTimeout={500}
          onChange={(event) => setFilterQuery(event.target.value.trim())}
        />
        <Button type="button" variant="contained" className="search-button">
          <SearchIcon />
        </Button>
      </div>

      {!filteredData?.length && <p className="title no-data-text">No data matches your search</p>}
      {filteredData?.length > 0 && (
        <p className="title">{filterQuery ? `Matches found: ${filteredData?.length}` : 'Popular Items'}</p>
      )}

      <div className="content">
        {filteredData?.map((list, index) => (
          <div key={index} className="item">
            <div className="photo-wrapper">
              <LazyLoadImage
                alt={list.strIngredient}
                effect="blur"
                src={
                  list.isLocal
                    ? require(`../assets/ingredientsPhotos/${list.strIngredient.toLowerCase()}.webp`)
                    : `https://www.themealdb.com/images/ingredients/${list.strIngredient}.png`
                }
              />
            </div>
            <Typography variant="body1">{list.strIngredient}</Typography>
            {shoppingList.includes(list.strIngredient) && (
              <button
                type="button"
                className="add-to-list-button in-list"
                onClick={async () => database.lists.where('name').equals(list.strIngredient).delete()}
              >
                Remove
              </button>
            )}
            {!shoppingList.includes(list.strIngredient) && (
              <button
                type="button"
                className="add-to-list-button"
                onClick={async () => {
                  await database.lists.add({
                    name: list.strIngredient,
                    quantity: 1,
                    price: 0,
                    isPurchased: false,
                    photoUrl: list.isLocal
                      ? require(`../assets/ingredientsPhotos/${list.strIngredient.toLowerCase()}.webp`)
                      : `https://www.themealdb.com/images/ingredients/${list.strIngredient}.png`,
                  })
                }}
              >
                add to list
              </button>
            )}
          </div>
        ))}
      </div>
      {!filterQuery && (
        <Pagination
          count={Math.round(data?.length / numberOfItemsPerPage)}
          className="pagination"
          size="large"
          showFirstButton
          showLastButton
          onChange={(_, page) => setPageData(page)}
        />
      )}
    </div>
  )
}

export default Popular
