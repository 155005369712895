import { useState } from 'react'
import { Autocomplete, Box, Button, TextField } from '@mui/material'
import data from '../popular/ingredients.json'

const FormField = ({ onSubmit, isError }) => {
  const [key, setKey] = useState('')
  const [open, setIsOpen] = useState(false)

  const filterOptions = (options, state) => {
    const filteredData = options
      ?.filter(({ strIngredient }) => strIngredient.toLowerCase().includes(state.inputValue.toLowerCase()))
      .slice(0, 30)
    return filteredData
  }

  return (
    <div>
      <Box
        component={'form'}
        className="form-field"
        onSubmit={(e) => {
          e.preventDefault()
          setKey(Date.now())
          onSubmit()
        }}
      >
        <Autocomplete
          id="name"
          open={open}
          freeSolo
          disableClearable
          key={key}
          options={data}
          filterOptions={filterOptions}
          getOptionLabel={(option) => (option['strIngredient'] ? option['strIngredient'] : option)}
          onClose={() => setIsOpen(false)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Name"
              className="label"
              autoFocus
              size="small"
              placeholder="Item..."
              variant="filled"
              error={isError}
              helperText={isError && 'Please fill this field!'}
              onChange={(event) => {
                if (event.target.value.length > 1) {
                  setIsOpen(true)
                } else setIsOpen(false)
              }}
            />
          )}
        />

        <div className="item-info">
          <TextField
            id="price"
            type="number"
            className="label"
            size="small"
            placeholder="0"
            label="Price"
            variant="filled"
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
            }}
          />
          <TextField
            id="quantity"
            type="number"
            className="label"
            size="small"
            placeholder="1"
            label="Quantity"
            variant="filled"
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4)
            }}
          />
        </div>

        <Button className="submit-button" type="submit" variant="contained" disableElevation>
          Add
        </Button>
      </Box>
    </div>
  )
}

export default FormField
