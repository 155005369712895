import { createState } from '@hookstate/core'

const store = createState({
  theme: localStorage.getItem('theme') || 'dark',
  currency: localStorage.getItem('currency') || '₦',
  modalStateData: {
    isOpen: null,
    message: '',
    action: '',
    listData: {},
    onClick: null,
    onSubmit: null,
    isError: false,
  },
})

export default store
