import { useState } from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { useNavigate } from 'react-router-dom'
import { useState as hookState } from '@hookstate/core'
import { Button, IconButton, Typography } from '@mui/material'
import { Delete, ReadMore } from '@mui/icons-material'
import { pencil } from '../assets'
import { ToggleFormButton, RecipeForm } from '../common'
import database from '../../database'
import store from '../../store'
import './recipes.scss'

const Recipes = () => {
  const navigate = useNavigate()
  const { modalStateData } = hookState(store)
  const [formFieldIsOpen, setFormFieldIsOpen] = useState(false)

  const allRecipes = useLiveQuery(() => database.recipes.reverse().toArray(), [])
  if (!allRecipes) return null

  const editRecipe = async (selectedRecipeId) => {
    const recipe = document.querySelector('#newRecipe').value

    if (recipe.trim().length >= 1) {
      await database.recipes.update(selectedRecipeId, { recipe })
      modalStateData.isError.set(false)
      modalStateData.isOpen.set(null)
    } else modalStateData.isError.set(true)
  }

  return (
    <div className="recipes">
      <p className="title">Hot recipes: {allRecipes.length}</p>

      {!allRecipes.length && <RecipeForm setFormFieldIsOpen={setFormFieldIsOpen} className="form" />}

      {allRecipes.length > 0 && (
        <div className="recipe-wrapper">
          <ToggleFormButton onClick={() => setFormFieldIsOpen(!formFieldIsOpen)} formFieldIsOpen={formFieldIsOpen} />

          {formFieldIsOpen && <RecipeForm setFormFieldIsOpen={setFormFieldIsOpen} className="form" />}

          <div className="recipe-list">
            {allRecipes?.map((item) => (
              <div key={item.id} className="recipe" onClick={() => navigate(`/recipes/${item.recipeId}`)}>
                <div className="recipe-info-wrapper">
                  <Typography variant="body1" className="name">
                    {item.recipe}
                  </Typography>
                  <Typography className="info" variant="caption">
                    {item.ingredients.length} ingredients
                  </Typography>
                </div>

                <div className="button-wrapper">
                  <IconButton className="read-more-button" aria-label="open recipe">
                    <ReadMore />
                  </IconButton>

                  <IconButton
                    className="edit-button"
                    aria-label="edit"
                    onClick={(event) => {
                      event.stopPropagation()
                      modalStateData.merge({
                        isOpen: 'editRecipe',
                        listData: { name: item.recipe },
                        onSubmit: () => editRecipe(item.id),
                      })
                    }}
                  >
                    <img src={pencil} alt="edit" />
                  </IconButton>

                  <IconButton
                    className="delete-button"
                    aria-label="delete"
                    onClick={(event) => {
                      event.stopPropagation()
                      modalStateData.merge({
                        isOpen: 'delete',
                        message: `Are you sure you want to permanently delete ${item.recipe}?`,
                        action: 'Delete',
                        onClick: async () => {
                          await database.recipes.delete(item.id)
                          modalStateData.isOpen.set(null)
                        },
                      })
                    }}
                  >
                    <Delete />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>

          <Button
            variant="contained"
            className="clear-all"
            onClick={() =>
              modalStateData.merge({
                isOpen: 'delete',
                action: 'clear',
                message: `Do you want to permanently clear all recipes?`,
                onClick: async () => {
                  await database.recipes.clear()
                  modalStateData.isOpen.set(null)
                },
              })
            }
          >
            Clear All
          </Button>
        </div>
      )}
    </div>
  )
}

export default Recipes
