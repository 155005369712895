import { useNavigate } from 'react-router-dom'
import { FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import PolicyIcon from '@mui/icons-material/Policy'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import MaterialUISwitch from '../common/MaterialUISwitch'
import { useHookstate } from '@hookstate/core'
import store from '../../store'
import './settings.scss'

const Settings = () => {
  const { theme, currency } = useHookstate(store)
  const navigate = useNavigate()

  const switchTheme = () => {
    const currentTheme = theme.get() === 'dark' ? 'light' : 'dark'
    theme.set(currentTheme)
    localStorage.setItem('theme', currentTheme)
  }

  const changeListCurrency = (value) => {
    localStorage.setItem('currency', value)
    currency.set(value)
  }

  return (
    <div className="settings">
      <Typography variant="h4" className="header">
        Settings
      </Typography>

      <div className="content">
        <IconButton className="button" onClick={() => navigate('/privacy-policy')} aria-label="privacy policy">
          <div className="label">
            <PolicyIcon />
            Privacy & Security
          </div>
          <ArrowForwardIosIcon />
        </IconButton>

        <IconButton className="button" aria-label="change currency">
          <div className="label">
            <CurrencyExchangeIcon />
            Select Currency
          </div>
          <FormControl sx={{ textAlign: 'left' }}>
            <InputLabel id="select-currency">currency</InputLabel>
            <Select
              labelId="select-currency"
              value={currency.get()}
              label="currency"
              onChange={(event) => changeListCurrency(event.target.value)}
            >
              <MenuItem value="₦">Naira: ₦</MenuItem>
              <MenuItem value="£">Pound: £</MenuItem>
              <MenuItem value="€">Euro: €</MenuItem>
              <MenuItem value="$">Dollar: $</MenuItem>
              <MenuItem value="¥">Yen: ¥</MenuItem>
              <MenuItem value="GH₵">Cedi: GH₵</MenuItem>
            </Select>
          </FormControl>
        </IconButton>

        <IconButton className="button" onClick={switchTheme} aria-label="theme switcher">
          <div className="label">
            <VisibilityOutlinedIcon />
            Switch Theme
          </div>
          <FormControlLabel
            label=""
            control={
              <MaterialUISwitch
                name="theme-switcher"
                checked={theme.get() === 'dark' && true}
                aria-label="switch theme"
              />
            }
          />
        </IconButton>
      </div>
    </div>
  )
}

export default Settings
