import { Dexie } from 'dexie'

const database = new Dexie('MarketList')
database.version(1).stores({
  lists: '++id,name,quantity,price,isPurchased',
  recipes: '++id,recipeId,recipe,ingredients',
  trash: '++id,item',
})

export default database
