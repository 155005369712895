import { useHookstate } from '@hookstate/core'
import { createTheme, ThemeProvider } from '@mui/material'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { Lists, Popular, Recipes, Trash, Welcome, Ingredients, Settings, NotFoundPage } from './components'
import { BottomNav, DoActionModal, PageHeader } from './components/common'
import PrivacyPolicy from './components/PrivacyPolicy'
import store from './store'

const App = () => {
  const { theme } = useHookstate(store)
  const { pathname } = useLocation()

  const themePalette = createTheme({
    palette: {
      mode: theme.get(),
    },
  })

  const storedPageUrl = localStorage.getItem('storedPageUrl')

  return (
    <div className={`app ${theme.get()}`}>
      <ThemeProvider theme={themePalette}>
        {pathname !== '/' && <PageHeader />}

        <div className="pages">
          <Routes>
            <Route exact path="/" element={storedPageUrl ? <Navigate to="/lists" /> : <Welcome />} />
            <Route path="/lists" element={<Lists />} />
            <Route path="/popular" element={<Popular />} />
            <Route path="/recipes" element={<Recipes />} />
            <Route path="/recipes/:id" element={<Ingredients />} />
            <Route path="/trash" element={<Trash />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>

        {pathname !== '/' && <BottomNav />}

        <DoActionModal />
      </ThemeProvider>
    </div>
  )
}

export default App
