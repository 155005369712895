import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useLiveQuery } from 'dexie-react-hooks'
import { useState as hookState } from '@hookstate/core'
import { Delete } from '@mui/icons-material'
import { IconButton, Button, Typography } from '@mui/material'
import { pencil, emptyBox } from '../assets'
import { EmptyListMessage, RecipeForm, ToggleFormButton } from '../common'
import database from '../../database'
import store from '../../store'
import NotFoundPage from '../notFoundPage'
import './recipes.scss'

const Ingredients = () => {
  const { id } = useParams()
  const [formFieldIsOpen, setFormFieldIsOpen] = useState(false)
  const { modalStateData } = hookState(store)

  const allListItems = useLiveQuery(() => database.lists.reverse().toArray(), [])
  const allRecipes = useLiveQuery(() => database.recipes.reverse().toArray(), [])
  if (!allRecipes || !allListItems) return null

  const selectedRecipe = allRecipes?.filter((recipes) => recipes.recipeId === id)
  const { recipe, ingredients } = selectedRecipe[0] || {}

  const filteredIngredients = ingredients.filter((ingredient) => !allListItems.find(({ name }) => name === ingredient))

  const editIngredient = async (index) => {
    const newIngredient = document.querySelector('#newIngredient').value

    if (newIngredient.trim().length >= 1) {
      ingredients[index] = newIngredient
      await database.recipes.update(selectedRecipe[0].id, { ingredients })
      modalStateData.isError.set(false)
      modalStateData.isOpen.set(null)
    } else modalStateData.isError.set(true)
  }

  if (!selectedRecipe.length) return <NotFoundPage />

  return (
    <div className="ingredients">
      <div className="header">
        <Typography variant="h5">
          Recipe Title: <br /> {recipe}
        </Typography>
        <ToggleFormButton onClick={() => setFormFieldIsOpen(!formFieldIsOpen)} formFieldIsOpen={formFieldIsOpen} />
      </div>

      {formFieldIsOpen && (
        <RecipeForm
          selectedRecipeId={selectedRecipe[0].id}
          ingredientsFormOnly={true}
          setFormFieldIsOpen={setFormFieldIsOpen}
          ingredients={ingredients}
        />
      )}

      {!ingredients.length && <EmptyListMessage illustration={emptyBox} message="No ingredients for this recipe yet" />}

      {ingredients?.length > 0 && (
        <div className="list-wrapper">
          <Typography variant="body1">All Ingredients: {ingredients.length}</Typography>
          <div className="lists">
            {ingredients.map((item, index) => (
              <div key={index} className="item">
                <Typography className="name" variant="body1">
                  {item}
                </Typography>
                <div className="button-wrapper">
                  <IconButton
                    variant="contained"
                    className="edit-button"
                    onClick={() => {
                      modalStateData.merge({
                        isOpen: 'editIngredient',
                        listData: {
                          id: index,
                          name: item,
                        },
                        onSubmit: () => editIngredient(index),
                      })
                    }}
                  >
                    <img src={pencil} alt="edit" />
                  </IconButton>
                  <IconButton
                    variant="contained"
                    className="delete-button"
                    onClick={() => {
                      modalStateData.merge({
                        isOpen: 'delete',
                        message: `Are you sure you want to permanently delete ${item}?`,
                        action: 'delete',
                        onClick: async () => {
                          ingredients.splice(index, 1)
                          await database.recipes.update(selectedRecipe[0].id, { ingredients })
                          modalStateData.isOpen.set(null)
                        },
                      })
                    }}
                  >
                    <Delete />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>

          <div className="footer">
            {filteredIngredients.length > 0 ? (
              <Button
                variant="contained"
                className="add-to-list-button"
                onClick={() => {
                  filteredIngredients?.forEach(async (ingredient) => {
                    await database.lists.add({
                      name: ingredient,
                      quantity: 1,
                      price: 0,
                      isPurchased: false,
                    })
                  })
                }}
              >
                Add all to list
              </Button>
            ) : (
              <Button
                variant="contained"
                className="add-to-list-button in-list-button"
                onClick={() =>
                  ingredients?.forEach(async (ingredient) => database.lists.where('name').equals(ingredient).delete())
                }
              >
                Remove all from list
              </Button>
            )}

            <Button
              variant="contained"
              className="clear-all-button"
              onClick={() =>
                modalStateData.merge({
                  isOpen: 'delete',
                  message: `Are you sure you want to permanently delete all ingredients for ${recipe}?`,
                  onClick: async () => {
                    await database.recipes.update(selectedRecipe[0].id, { ingredients: [] })
                    modalStateData.isOpen.set(null)
                    setFormFieldIsOpen(true)
                  },
                })
              }
            >
              Clear ingredients
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Ingredients
