import { useLiveQuery } from 'dexie-react-hooks'
import { useState } from 'react'
import { useState as hookState } from '@hookstate/core'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Button, Checkbox, IconButton, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { pencil, emptyBox, emptyCart } from '../assets'
import { FormField, EmptyListMessage, ToggleFormButton } from '../common'
import database from '../../database'
import data from '../popular/ingredients.json'
import store from '../../store'
import 'react-lazy-load-image-component/src/effects/blur.css'
import './lists.scss'

const Lists = () => {
  const { modalStateData, currency } = hookState(store)
  const [formFieldIsOpen, setFormFieldIsOpen] = useState(false)
  const [filterParam, setFilterParam] = useState('All')
  const [inputFieldIsEmpty, setInputFieldIsEmpty] = useState(false)

  const allItems = useLiveQuery(() => database.lists.reverse().toArray(), [])

  if (!allItems) return null

  let filteredItems

  if (filterParam === 'Purchased') {
    filteredItems = allItems.filter((item) => item.isPurchased)
  } else if (filterParam === 'UnPurchased') {
    filteredItems = allItems.filter((item) => !item.isPurchased)
  } else {
    filteredItems = allItems
  }

  const purchasedItems = filteredItems.filter((item) => item.isPurchased)
  const totalPrice = filteredItems?.map((item) => item.price * item.quantity).reduce((a, b) => a + b, 0)

  const updateSelectedItem = async (selectedItemId) => {
    const newName = document.querySelector('#newName').value
    const newQuantity = document.querySelector('#newQuantity').value || 1
    const newPrice = document.querySelector('#newPrice').value || 0

    if (newName.trim().length >= 1) {
      await database.lists.update(selectedItemId, {
        name: newName,
        quantity: newQuantity,
        price: newPrice,
        isPurchased: false,
      })
      modalStateData.isError.set(false)
      modalStateData.isOpen.set(null)
    } else modalStateData.isError.set(true)
  }

  const getPhotoUrl = (name) => {
    const filteredData = data.filter((list) => list.strIngredient.toLowerCase() === name)
    let photoUrl

    if (filteredData.length > 0 && filteredData[0].isLocal) {
      return (photoUrl = require(`../assets/ingredientsPhotos/${name}.webp`))
    }

    if (filteredData.length > 0 && !filteredData[0].isLocal) {
      return (photoUrl = `https://www.themealdb.com/images/ingredients/${name}.png`)
    }

    if (!filteredData.length) {
      return (photoUrl = '')
    }

    return photoUrl
  }

  const addItem = async () => {
    const name = document.querySelector('#name').value
    const quantity = document.querySelector('#quantity').value || 1
    const price = document.querySelector('#price').value || 0

    if (name.trim().length >= 1) {
      await database.lists.add({
        name,
        quantity,
        price,
        isPurchased: false,
        photoUrl: getPhotoUrl(name.toLowerCase()),
      })
      setFilterParam('All')

      document.querySelector('#quantity').value = ''
      document.querySelector('#price').value = ''
      setInputFieldIsEmpty(false)
    } else setInputFieldIsEmpty(true)
  }

  return (
    <div className="lists">
      {!allItems?.length && (
        <>
          <FormField onSubmit={addItem} isError={inputFieldIsEmpty} />

          <EmptyListMessage illustration={emptyCart} message="Your list is empty" />
        </>
      )}

      {allItems?.length > 0 && (
        <>
          <div className="select-form-wrapper">
            <FormControl className={'select-form'} size="small" fullWidth variant="filled">
              <InputLabel className="label">Filter List</InputLabel>

              <Select
                name="filter-list"
                value={filterParam}
                label="filter list"
                onChange={(event) => {
                  setFilterParam(event.target.value)
                  setFormFieldIsOpen(false)
                }}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Purchased">Purchased</MenuItem>
                <MenuItem value="UnPurchased">Unpurchased</MenuItem>
              </Select>
            </FormControl>

            <ToggleFormButton
              onClick={() => {
                setFormFieldIsOpen(!formFieldIsOpen)
                setInputFieldIsEmpty(false)
              }}
              formFieldIsOpen={formFieldIsOpen}
            />
          </div>

          {formFieldIsOpen && <FormField onSubmit={addItem} isError={inputFieldIsEmpty} />}

          {filteredItems?.length > 0 && (
            <>
              <div className="item-wrapper">
                {filteredItems?.map(({ id, name, price, quantity, isPurchased, photoUrl }, index) => (
                  <div
                    key={index}
                    className={`item ${isPurchased ? 'purchased' : 'unPurchased'}`}
                    onClick={async () => await database.lists.update(id, { isPurchased: !isPurchased })}
                  >
                    <div className="left-column">
                      {photoUrl && (
                        <div className="photo-wrapper">
                          <LazyLoadImage alt={name} effect="blur" src={photoUrl} />
                        </div>
                      )}
                      <div>
                        <Typography className="name" variant="body1">
                          {name}
                        </Typography>
                        <Typography className="info" variant="caption">
                          {currency.get()}
                          {price} X {quantity}pcs
                        </Typography>
                      </div>
                    </div>

                    <div className="list-buttons-wrapper">
                      <Checkbox
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                        color="success"
                        name="checkbox"
                        checked={isPurchased}
                        onChange={async () => {
                          await database.lists.update(id, { isPurchased: !isPurchased })
                        }}
                      />

                      <IconButton
                        className="edit-button"
                        aria-label="edit"
                        onClick={(event) => {
                          event.stopPropagation()
                          modalStateData.merge({
                            isOpen: 'edit',
                            listData: { name, quantity, price },
                            onSubmit: () => updateSelectedItem(id),
                          })
                        }}
                      >
                        <img src={pencil} alt="edit" />
                      </IconButton>

                      <IconButton
                        className="delete-button"
                        aria-label="delete"
                        onClick={(event) => {
                          event.stopPropagation()
                          modalStateData.merge({
                            isOpen: 'delete',
                            message: `Move ${name} to trash?`,
                            action: 'move',
                            onClick: async () => {
                              await database.lists.delete(id)
                              await database.trash.add({
                                name,
                                price,
                                quantity,
                                isPurchased,
                                photoUrl,
                                time: Date.now(),
                              })
                              modalStateData.isOpen.set(null)
                            },
                          })
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>

              <p className="total-price">
                Total Price: {currency.get()}
                {totalPrice}
              </p>

              <div className="footer">
                {purchasedItems.length > 0 && (
                  <Button
                    variant="contained"
                    onClick={() =>
                      modalStateData.merge({
                        isOpen: 'delete',
                        action: 'clear',
                        message: `Are you sure you want to permanently clear all purchased items?`,
                        onClick: async () => {
                          const itemIds = purchasedItems.map(({ id }) => id)
                          await database.lists.bulkDelete(itemIds)
                          modalStateData.isOpen.set(null)
                        },
                      })
                    }
                  >
                    Clear purchased
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={() =>
                    modalStateData.merge({
                      isOpen: 'delete',
                      action: 'clear',
                      message: `Are you sure you want to permanently clear all items?`,
                      onClick: async () => {
                        await database.lists.clear()
                        modalStateData.isOpen.set(null)
                      },
                    })
                  }
                >
                  Clear All
                </Button>
              </div>
            </>
          )}

          {!filteredItems?.length && (
            <EmptyListMessage
              illustration={emptyBox}
              message={`Looks like you don't have any ${filterParam.toLowerCase()} list yet.`}
            />
          )}
        </>
      )}
    </div>
  )
}

export default Lists
