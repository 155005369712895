import { useState } from 'react'
import { Button, TextField } from '@mui/material'
import database from '../../database'

const RecipeForm = ({
  setFormFieldIsOpen,
  selectedRecipeId,
  ingredientsFormOnly = false,
  ingredients,
  className = '',
}) => {
  const [isError, setIsError] = useState({ textBox: '' })
  const [ingredientsTextBox, setIngredientsTextBox] = useState(['', ''])
  const newIngredientValues = ingredientsTextBox.filter((data) => data !== '')

  const createRecipe = async (event) => {
    event.preventDefault()
    const recipe = document.querySelector('#nameOfRecipe').value

    if (recipe.trim().length >= 1) {
      const recipeId = 'id' + Math.random().toString(16).slice(2)
      await database.recipes.add({ recipeId, recipe, ingredients: newIngredientValues })
      setFormFieldIsOpen(false)
    } else setIsError({ textBox: 'recipe' })
  }

  const addIngredientsToDb = async (event) => {
    event.preventDefault()
    if (newIngredientValues.length > 0) {
      await database.recipes.update(selectedRecipeId, { ingredients: [...newIngredientValues, ...ingredients] })
      setFormFieldIsOpen(false)
    } else setIsError({ textBox: 'ingredients' })
  }

  const handleInputChange = (value, index) => {
    const newData = [...ingredientsTextBox]
    newData[index] = value
    setIngredientsTextBox(newData)
  }

  return (
    <form
      className={`recipe-form ${className}`}
      onSubmit={(event) => (selectedRecipeId ? addIngredientsToDb(event) : createRecipe(event))}
    >
      {!ingredientsFormOnly && <p className="form-title">Recipe Title</p>}
      {!ingredientsFormOnly && (
        <TextField
          type="text"
          id="nameOfRecipe"
          placeholder="Recipe title"
          autoComplete="off"
          error={isError.textBox === 'recipe'}
          helperText={isError.textBox === 'recipe' && 'Please fill this field!'}
          inputProps={{ maxLength: 30 }}
        />
      )}
      <p className="form-title">Ingredients</p>

      <div className="ingredients-wrapper">
        {isError.textBox === 'ingredients' && (
          <p className="ingredients-error-message">At least one ingredients must be added</p>
        )}
        {ingredientsTextBox.map((_, index) => (
          <TextField
            key={index}
            type="text"
            placeholder="Enter Ingredient"
            autoComplete="off"
            onChange={(e) => handleInputChange(e.target.value.trim(), index)}
            inputProps={{ maxLength: 20 }}
          />
        ))}
        <Button
          type="button"
          className="add-field-button"
          variant="contained"
          onClick={() => setIngredientsTextBox([...ingredientsTextBox, ''])}
          disableElevation
        >
          <span>+ Add Field</span>
        </Button>
      </div>

      <Button type="submit" className="submit-button" variant="contained" disableElevation>
        <span>{ingredientsFormOnly ? 'add ingredients' : 'create recipe'}</span>
      </Button>
    </form>
  )
}

export default RecipeForm
