import { useState as hookState } from '@hookstate/core'
import { Close } from '@mui/icons-material'
import { Modal, Fade, Box, Button, TextField, Typography } from '@mui/material'
import { useLiveQuery } from 'dexie-react-hooks'
import database from '../../database'
import store from '../../store'

const DoActionModal = () => {
  const allItems = useLiveQuery(() => database.recipes.reverse().toArray(), [])
  const { modalStateData, theme } = hookState(store)
  const { isOpen, message, action, listData, onClick, onSubmit, isError } = modalStateData.get()

  if (!allItems) return null

  const DeleteModal = () => (
    <div className="delete-modal">
      <Typography variant="h5">{message}</Typography>
      <Button
        className="cancel-button"
        variant="contained"
        disableElevation
        onClick={() => modalStateData.merge({ isOpen: null, action: '' })}
      >
        Cancel
      </Button>

      <Button className="delete-button" disableElevation onClick={onClick}>
        {action || 'delete'}
      </Button>
    </div>
  )

  const EditModal = () => (
    <div className="edit-modal">
      <div className="header">
        <Button
          className={'close-button'}
          onClick={() => modalStateData.merge({ isOpen: null, isError: false })}
          disableElevation
        >
          <Close />
        </Button>

        <Typography variant="h6" sx={{ width: '100%' }}>
          Update List
        </Typography>
      </div>
      <div>
        <Box
          component={'form'}
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit()
          }}
          className="form-field"
        >
          <TextField
            id="newName"
            type="text"
            label="Name"
            autoComplete="off"
            placeholder="Item..."
            variant="filled"
            defaultValue={listData.name}
            error={isError}
            helperText={isError && 'Please fill this field!'}
            inputProps={{ maxLength: 20 }}
          />
          <div className="item-info">
            <TextField
              id="newPrice"
              type="number"
              placeholder="0"
              label="Price"
              variant="filled"
              defaultValue={listData.price}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
              }}
            />
            <TextField
              id="newQuantity"
              type="number"
              placeholder="1"
              label="Quantity"
              variant="filled"
              defaultValue={listData.quantity}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4)
              }}
            />
          </div>

          <Button className="update-button" type="submit" variant="contained" disableElevation>
            <span>Update</span>
          </Button>
        </Box>
      </div>
    </div>
  )

  const EditRecipeModal = () => (
    <div className="edit-recipe-modal">
      <Typography variant="h5">Update recipe</Typography>

      <form
        className="recipe-form"
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <TextField
          type="text"
          id="newRecipe"
          label="Title"
          variant="filled"
          autoComplete="off"
          defaultValue={listData.name}
          error={isError}
          helperText={isError && 'Please fill this field!'}
          inputProps={{ maxLength: 30 }}
        />
        <Button type="submit" className="update-button" variant="contained" disableElevation>
          <span>Update</span>
        </Button>
      </form>
    </div>
  )

  const EditIngredientModal = () => (
    <div className="edit-recipe-modal">
      <Typography variant="h5">Update ingredient</Typography>

      <form
        className="recipe-form"
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <TextField
          type="text"
          id="newIngredient"
          label="Ingredient"
          variant="filled"
          autoComplete="off"
          defaultValue={listData.name}
          error={isError}
          helperText={isError && 'Please fill this field!'}
          inputProps={{ maxLength: 20 }}
        />
        <Button type="submit" className="update-button" variant="contained" disableElevation>
          <span>Update</span>
        </Button>
      </form>
    </div>
  )

  return (
    isOpen && (
      <>
        <Modal
          open={true}
          className={`modal ${theme.get() === 'dark' ? 'modal-dark' : ''}`}
          onClose={() => modalStateData.merge({ isOpen: null, isError: false, action: '' })}
          aria-labelledby="modal"
          aria-describedby="modal"
        >
          <Fade in={true}>
            <Box className="box">
              {isOpen === 'delete' && <DeleteModal />}
              {isOpen === 'edit' && <EditModal />}
              {isOpen === 'editRecipe' && <EditRecipeModal />}
              {isOpen === 'editIngredient' && <EditIngredientModal />}
            </Box>
          </Fade>
        </Modal>
      </>
    )
  )
}

export default DoActionModal
