import { AddOutlined } from '@mui/icons-material'
import './common.scss'

const ToggleFormButton = ({ onClick, formFieldIsOpen }) => {
  return (
    <button type="button" className={`toggle-form-button ${formFieldIsOpen && 'cross'}`} onClick={onClick}>
      <AddOutlined sx={{ fontSize: 40 }} className="icon" />
    </button>
  )
}

export default ToggleFormButton
