import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import { manWithList } from '../assets'
import { EmptyListMessage } from '../common'
import './welcome.scss'

const Welcome = () => {
  const navigate = useNavigate()

  return (
    <div className={'welcome'}>
      <Typography variant="h4">
        <span>Mamas </span>List
      </Typography>

      <div className="content">
        <EmptyListMessage
          illustration={manWithList}
          message="Feature rich and easy to use."
          className="empty-list-message"
        />

        <button
          className={'start-button'}
          onClick={() => {
            navigate('/lists')
            localStorage.setItem('storedPageUrl', '/lists')
          }}
        >
          Get Started
        </button>
      </div>
    </div>
  )
}

export default Welcome
