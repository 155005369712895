import { notFound } from '../assets'
import { EmptyListMessage } from '../common'
import './notFound.scss'

const NotFoundPage = () => {
  return (
    <div className="not-found-page">
      <EmptyListMessage illustration={notFound} message="Oops... Not Found" />
    </div>
  )
}

export default NotFoundPage
