import { useLocation, useNavigate } from 'react-router-dom'
import { ArrowBackIosSharp } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'

const PageHeader = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const hasPreviousPage = location.key !== 'default'
  const name = location.pathname.replace(/[^a-zA-Z ]/g, '')

  return (
    <div className="page-header">
      {name !== 'lists' && (
        <IconButton
          className="back-button"
          aria-label="back"
          onClick={() => {
            navigate(hasPreviousPage ? -1 : '/lists')
          }}
        >
          <ArrowBackIosSharp />
        </IconButton>
      )}

      <Typography variant="h4" className="app-name">
        <span>Mamas </span>List
      </Typography>
    </div>
  )
}

export default PageHeader
