const EmptyListMessage = ({ illustration, message, className = '' }) => {
  return (
    <div className={`empty-list-illustration ${className}`}>
      <img src={illustration} alt="illustration" />
      <p>{message}</p>
    </div>
  )
}

export default EmptyListMessage
