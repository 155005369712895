import { useLiveQuery } from 'dexie-react-hooks'
import { useState as hookState } from '@hookstate/core'
import { Button, IconButton, Typography } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import RestoreIcon from '@mui/icons-material/Restore'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { emptyTrash } from '../assets'
import { EmptyListMessage } from '../common'
import database from '../../database'
import store from '../../store'
import './trash.scss'

const Trash = () => {
  const { modalStateData, currency } = hookState(store)
  const trashItems = useLiveQuery(() => database.trash.reverse().toArray(), [])
  if (!trashItems) return null

  const clearTrashAfter30Days = async () => {
    for (let index = 0; index < trashItems?.length; index++) {
      const item = trashItems[index]
      const noOfDaysInTrash = Math.floor((Date.now() - item?.time) / (1000 * 60 * 60 * 24))

      if (noOfDaysInTrash > 30) {
        await database.trash.delete(item.id)
      }
    }
  }

  clearTrashAfter30Days()

  return (
    <div className="trash">
      {!trashItems.length && (
        <>
          <EmptyListMessage illustration={emptyTrash} message="Empty Trash" />

          <Typography variant="subtitle" className="empty-trash-info">
            Items will be permanently deleted after 30 days in Trash.
          </Typography>
        </>
      )}

      {trashItems.length > 0 && (
        <div className="item-wrapper">
          <Typography variant="subtitle" className="empty-trash-info">
            Items will be permanently deleted after 30 days in Trash.
          </Typography>

          {trashItems.map(({ id, name, price, quantity, isPurchased, photoUrl }, index) => (
            <div key={index} className={`item ${isPurchased ? 'purchased' : 'unPurchased'}`}>
              <div className="left-column">
                {photoUrl && (
                  <div className="photo-wrapper">
                    <LazyLoadImage alt={name} effect="blur" src={photoUrl} />
                  </div>
                )}

                <div>
                  <Typography className="name" variant="body1">
                    {name}
                  </Typography>
                  <Typography className="info" variant="caption">
                    {currency.get()}
                    {price} X {quantity}pcs
                  </Typography>
                </div>
              </div>

              <div className="list-buttons-wrapper">
                <IconButton
                  className="restore-button"
                  aria-label="restore"
                  onClick={() => {
                    modalStateData.merge({
                      isOpen: 'delete',
                      action: 'restore',
                      message: `Do you want to restore ${name}?`,
                      onClick: async () => {
                        await database.lists.add({ name, quantity, price, isPurchased, photoUrl })
                        await database.trash.delete(id)
                        modalStateData.isOpen.set(null)
                      },
                    })
                  }}
                >
                  <RestoreIcon />
                </IconButton>

                <IconButton
                  className="delete-button"
                  aria-label="delete"
                  onClick={() => {
                    modalStateData.merge({
                      isOpen: 'delete',
                      action: 'delete',
                      message: `Are you sure you want to permanently delete ${name}?`,
                      onClick: async () => {
                        await database.trash.delete(id)
                        modalStateData.isOpen.set(null)
                      },
                    })
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </div>
            </div>
          ))}

          <Button
            variant="contained"
            className="empty-trash-button"
            onClick={() =>
              modalStateData.merge({
                isOpen: 'delete',
                action: 'empty',
                message: `Are you sure you want to empty trash?`,
                onClick: async () => {
                  await database.trash.clear()
                  modalStateData.isOpen.set(null)
                },
              })
            }
          >
            Empty Trash
          </Button>
        </div>
      )}
    </div>
  )
}

export default Trash
